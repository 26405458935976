// 意见反馈
<script>
import api from '@/command/api'
import TemplateHeaderTable from '../../../components/TemplateHeaderTable'
import apiTool from '@/command/apiTool'
import DrawerForm from '../../../components/DrawerForm'
import moment from 'moment'
import { getAction } from '@/command/netTool'
import { byAuditTypeList } from '@/utils/textFile'

export default {
  name: 'feedback',
  data() {
    return {
      ...api.command.getState(),
    }
  },
  mounted() {
    api.command.getList.call(this, {
      url: '/farmPurchaseAgricultural/page',
      current: 1,
    })
  },
  methods: {
    getHeader() {
      return [
        {
          name: '申请单号',
          type: 'input',
          key: 'purchaseNo',
        },
        {
          name: '反馈时间',
          type: 'rangePicker',
          keys: ['submitDateGe', 'submitDateLe'],
        },
        {
          name: '手机号码',
          type: 'input',
          key: 'contactPhone',
        },
      ]
    },
    getColumns() {
      let _this = this
      return [
        {
          dataIndex: 'purchaseNo',
          title: '申请单号',
          align: 'left',
        },
        {
          dataIndex: 'productDesc',
          title: '商品描述',
          align: 'left',
        },
        {
          dataIndex: 'contactName',
          title: '联系人',
          align: 'left',
        },
        {
          dataIndex: 'contactPhone',
          title: '联系电话',
          align: 'left',
        },
        {
          dataIndex: 'createTime',
          title: '提交时间',
          filter: true,
          align: 'left',
          sorter: (a, b) => moment(a.createTime).unix() - moment(b.createTime).unix(),
        },
        {
          dataIndex: 'action',
          title: '操作',
          type: 'buttonGroup',
          typeData: ({ records }) => {
            return [
              {
                name: records.purchaseStatus == '0' ? '处理' : '已处理',
                disabled: records.purchaseStatus !== '0',
                onClick: () => records.purchaseStatus == '0' && _this.handlePropup(records),
              },
              {
                name: '删除',
                type: 'pop',
                popTitle: '确认删除吗?',
                onClick: () =>
                  api.command.del.call(this, {
                    url: `/farmPurchaseAgricultural?id=${records.id}`,
                  }),
              },
            ]
          },
        },
      ]
    },
    handlePropup(records) {
      apiTool.showDrawer({
        title: '回复',
        width: '600px',
        form: [
          {
            name: '处理结果',
            type: 'textArea',
            key: 'content',
            placeholder: '请输入处理结果',
          },
        ],
        success: ({ data, setHidden }) => {
          if (data.content) {
            api.command.edit
              .call(this, {
                url: `/farmPurchaseAgricultural/handle`,
                params: { id: records.id, handleNote: data.content },
                title: '处理成功',
              })
              .then(setHidden)
          } else {
            this.$message.warning('请填写回复内容！')
          }
        },
        foot: ({ close, submit }) => {
          return [
            {
              name: '返回',
              onClick: close,
            },
            {
              name: '确认',
              type: 'primary',
              onClick: submit,
            },
          ]
        },
      })
    },
    getButton() {
      return [
        {
          viewType: 'menu',
          name: '更多操作',
          isBatch: true,
          children: [
            {
              name: '批量删除',
              onClick: (data) => {
                let id = data.map((e) => e.id)
                return api.command.del.call(this, {
                  url: '/farmPurchaseAgricultural',
                  params: { id: id.toString() },
                })
              },
            },
          ],
        },
      ]
    },
    getDrawerForm(records) {
      const form = [
        {
          title: '基础信息',
          display: records.createTime,
          form: [
            {
              type: 'textGroup',
              value: [
                {
                  name: '受理单号',
                  value: records.purchaseNo,
                  display: records.purchaseNo,
                },
                {
                  name: '联系人',
                  value: records.contactName,
                  display: records.contactName,
                },
                {
                  name: '提交时间',
                  value: records.createTime,
                  display: records.createTime,
                },
                {
                  name: '联系手机',
                  value: records.contactPhone,
                  display: records.contactPhone,
                },
              ].filter((e) => e.display),
            },
          ],
        },
        {
          title: '商品信息',
          display: true,
          form: [
            {
              type: 'textGroup',
              value: [
                {
                  name: '商品名称',
                  value: records.productName,
                  display: records.productName,
                },
                {
                  name: '购买数量',
                  value: records.buyCount,
                  display: records.buyCount,
                },
              ].filter((e) => e.display),
            },
          ],
        },
        {
          title: '商品描述',
          display: records.productDesc,
          form: [
            {
              type: 'text',
              value: records.productDesc,
            },
            {
              type: 'imageGroup',
              value: records.imgUrl,
            },
          ],
        },
        {
          title: '处理结果',
          display: records.purchaseStatus == '1' && records.handleNote,
          form: [
            {
              type: 'text',
              value: records.handleNote,
            },
          ],
        },
      ].filter((e) => e.display)
      return form
    },
    onRowSelect(records) {
      const that = this
      if (records.id) {
        getAction('/farmPurchaseAgricultural/detail', { id: records.id }, '/api').then((res) => {
          const data = this.getDrawerForm(res.data || records)
          apiTool.showDrawer({
            title: '详情',
            width: '700px',
            view: DrawerForm,
            viewProps: { data },
            success: ({ data, setHidden }) => {
              setHidden()
              that.handlePropup(records)
            },
            foot: ({ close, submit }) => {
              if (records.status == '0') {
                return [
                  {
                    name: '取消',
                    onClick: close,
                  },
                  {
                    name: '处理',
                    type: 'primary',
                    onClick: submit,
                  },
                ]
              } else {
                return []
              }
            },
          })
        })
      }
    },
  },
  render() {
    return (
      <TemplateHeaderTable
        headerData={this.getHeader()}
        tableColumns={this.getColumns()}
        button={this.getButton()}
        onSelectRow={this.onRowSelect}
        records={this.records}
      />
    )
  },
}
</script>
<style scoped lang="less">
.content-box {
  max-width: 300px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.product-order-item {
  display: flex;
  align-items: center;

  img {
    width: 30px;
    height: 30px;
    margin-right: 10px;
    border-radius: 5px;
    object-fit: cover;
  }
}
</style>
